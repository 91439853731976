<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 62.11 62.11"
    >
        <g>
            <g>
                <path
                    class="cls-1"
                    d="M31.05 0C13.9 0 0 13.9 0 31.05s13.9 31.06 31.05 31.06 31.06-13.9 31.06-31.06S48.2 0 31.05 0ZM19.04 44.77h-4.66l3.94-22.85h-3.25l.44-2.43 10.61-1.65-4.26 24.55a2.866 2.866 0 0 1-2.83 2.38h.01Zm10.77 0h-4.66l3.56-20.5a7.748 7.748 0 0 1 7.64-6.43h.55l-4.26 24.55a2.866 2.866 0 0 1-2.83 2.38Zm13.6-2.38a2.866 2.866 0 0 1-2.83 2.38h-4.66l3.56-20.5a7.748 7.748 0 0 1 7.64-6.43h.55l-4.26 24.55Z"
                />
            </g>
        </g>
    </svg>
</template>
<style scoped>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
