<template>
    <svg
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 49.14 496.37"
    ><path
        id="Layer_1-2"
        d="M49.14 248.18 0 0v496.37l49.14-248.19z"
        style="fill:#333;stroke-width:0"
    /></svg>
</template>
