<script setup>
import { computed } from 'vue'
import { Facebook, Instagram, Linkedin, Logo, X } from './Icons'
import { usePage } from '@inertiajs/vue3'

const page = usePage()

const isTestAccount = computed(() => {
    return page.props.auth.user ? page.props.auth.user.email == import.meta.env.VITE_TEST_ACCOUNT : false
})

</script>
<template>
    <footer
        class="bg-gray-700 text-gray-100 h-auto px-4 py-14 md:px-10 md:pt-20 md:pb-12"
    >
        <!-- show this when logged in user is a test account -->
        <div
            v-if="isTestAccount"
            class="bg-yellow-warning fixed top-0 z-50 w-full mx-auto left-0 px-2 text-center font-bold bg-opacity-80 text-red-primary"
        >
            -- This account is for demo purpose only --
        </div>
        <div
            class="max-w-5xl grid md:grid-cols-5 place-items-center mx-auto gap-4"
        >
            <div class="group my-2 md:my-0 md:col-start-3 w-16 h-16 text-white hover:text-red-primary hover:scale-110 duration-300 relative">
                <div class="group-hover:bg-white group-hover:scale-95 scale-0 w-full h-full rounded-full absolute top-0 left-0 duration-700"></div>
                <Link :href="route('home')">
                    <Logo class="w-full h-full relative" />
                </Link>
            </div>
            <div class="md:col-start-1 md:row-start-1">
                <Link
                    :href="route('about us')"
                    class="hover:font-bold uppercase"
                >
                    About Us
                </Link>
            </div>
            <div class="md:col-start-2 md:row-start-1">
                <Link
                    :href="route('why m1', { type: 'vendors' })"
                    class="uppercase hover:font-bold"
                >
                    Why M1Financial
                </Link>
            </div>
            <div>
                <a
                    v-if="$page.props.ziggy.url.includes('medonegroup')"
                    :href="route('contact')"
                    rel="noopener noreferrer"
                >CONTACT US</a>
                <Link
                    v-else
                    :href="route('contact')"
                    class="hover:font-bold"
                >
                    CONTACT US
                </Link>
            </div>
            <div>
                <Link
                    :href="route('simple application')"
                    class="py-2 px-8 rounded-md border hover:border-red-primary hover:bg-red-primary hover:text-white hover:font-bold duration-300 inline-block text-center"
                >
                    APPLY NOW
                </Link>
            </div>
        </div>
        <hr class="my-8 border-gray-300 max-w-2xl mx-auto lg:my-12" />
        <div
            class="md:flex items-center justify-between w-full max-w-5xl mx-auto space-y-4 md:space-y-0"
        >
            <p class="block text-center text-gray-200">
                All contents © <span id="currentYear">{{ new Date().getFullYear() }}</span>
                <a
                    v-if="$page.props.ziggy.url.includes('medonegroup')"
                    class="pl-2"
                    href="https://www.medonegroup.com"
                >Med One Group</a>
                <Link
                    v-else
                    :href="route('home')"
                    class="pl-2"
                >
                    M1 Financial
                </Link>. All Rights
                Reserved. <Link :href="route('privacy policy')"> Privacy Policy</Link>
            </p>
            <div class="flex items-center justify-center gap-6">
                <a
                    href="https://www.facebook.com/profile.php?id=61550577772224"
                    target="_blank"
                ><div
                    class="p-2 rounded-full bg-gray-500 shadow-lg w-10 h-10 text-white flex items-center justify-center hover:bg-gray-700 hover:text-blue-sky"
                >
                    <Facebook />
                </div></a>
                <a
                    href="https://www.instagram.com/m1financial_/"
                    target="_blank"
                ><div
                    class="p-2 rounded-full bg-gray-500 shadow-lg w-10 h-10 text-white flex items-center justify-center hover:bg-gray-700 hover:text-yellow-warning"
                >
                    <Instagram />
                </div></a>
                <a
                    href="https://www.linkedin.com/company/m1-financial"
                    target="_blank"
                ><div
                    class="p-2 rounded-full bg-gray-500 shadow-lg w-10 h-10 text-white flex items-center justify-center hover:bg-gray-700 hover:text-blue-light"
                >
                    <Linkedin />
                </div></a>
                <a
                    href="https://x.com/m1financial"
                    target="_blank"
                ><div
                    class="p-2 rounded-full bg-gray-500 shadow-lg w-10 h-10 text-white flex items-center justify-center hover:bg-gray-700"
                >
                    <X />
                </div></a>
            </div>
        </div>
    </footer>
</template>
